<template>
    <div class="py-2 shadow-lg min-h-20 z-10 relative">
        <div :class="innerClass">
            <slot />
        </div>
    </div>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = withDefaults(defineProps<{ wide?: boolean; class?: HTMLAttributes["class"] }>(), {
    wide: false,
    class: undefined,
});

const innerClass = computed(() => {
    const commonClasses = "flex gap-8 items-center";
    if (props.class) {
        return cn(commonClasses, props.class);
    } else {
        return cn(commonClasses, props.wide ? "container-left" : "container");
    }
});
</script>
